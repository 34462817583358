import api from "../Intercepters/AuthIntercepter";

const fetchSensors = async (userId) => {
  try {
    //get user's sensors list
    const url = `/sensors/by-user/${userId}`;
    const response = await api.get(url);
    return response.data.sensors;
  } catch (error) {
    console.error("Error fetching sensors:", error);
    throw error;
  }
};

export { fetchSensors };
