import React, { useState, useEffect, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Pagination, CircularProgress } from "@mui/material";
import { useUser } from "../../state/UserContext";
import AddSensorModal from "../../components/modals/sensors/AddSensorModal";
import UpdateSensorModal from "../../components/modals/sensors/UpdateSensorModal";
import DetailsSensorModal from "../../components/modals/sensors/DetailsSensorModal";
import { ThemeContext } from "../../state/ThemeContext";
import AddBoardWarningModal from "../../components/modals/sensors/AddBoardWarningModal";
import DeleteSensorModal from "../../components/modals/sensors/DeleteSensorModal";
import { fetchSensors } from "../../services/sensorService";
import { fetchBoards, getBoardNameById } from "../../services/boardService";
import GeneralButton from "../../components/Button";
import CustomSnackbar from "../../components/CustomSnackbar";
import api from "../../Intercepters/AuthIntercepter";

const columns = [
  { id: "sensor_type", label: "Type" },
  { id: "name", label: "Name" },
  { id: "is_realtime", label: "Status" },
  { id: "board", label: "Board" },
  { id: "action", label: "Action" },
];
const LoadingIndicator = () => (
  <TableRow>
    <TableCell colSpan="50%" align="center">
      <CircularProgress
        style={{ color: localStorage.getItem("theme-color") || "#168004" }}
      />
    </TableCell>
  </TableRow>
);

const SensorsPage = () => {
  const { theme } = useContext(ThemeContext);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    borderRadius: "16px",
    color: theme === "dark" ? "white" : "black",
    backgroundColor: theme === "dark" ? "#1E1E1E" : "#FFFFFF",
    boxShadow: 24,
    paddingTop: 3,
    paddingLeft: "30px",
    paddingRight: "30px",
  };
  if (window.matchMedia("(max-width: 600px)").matches) {
    style.width = "300px";
  }
  const { userData } = useUser();
  const [page, setPage] = useState(0);
  const [sensorsPerPage, setsensorsPerPage] = useState(9);
  const [selected, setSelected] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [showSuccessDelete, setShowSuccessDelete] = useState(false);
  const [selectedSensor, setSelectedSensor] = useState([]);
  const [openNoBoards, setOpenNoBoards] = useState(false);
  const [nbOfBoards, setNbOfBoards] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userBoards, setUserBoards] = useState([]);
  const [defaultBoardId, setDefaultBoardId] = useState("");

  const fetchDefaultBoard = async () => {
    try {
      setIsLoading(true);
      const boards = await fetchBoards(userData.id);
      if (boards.length === 0) {
        return;
      }
      setUserBoards(boards);
      const defaultId = boards[0].id;
      setDefaultBoardId(defaultId);
      setFormData((prevState) => ({
        ...prevState,
        board: defaultId,
      }));
    } catch (error) {
      console.error("Error fetching boards:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const userNbBoards = parseInt(localStorage.getItem("nb_boards")) || 0;
    setNbOfBoards(userNbBoards);
  });
  useEffect(() => {
    fetchDefaultBoard();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const sensorsData = await fetchSensors(userData.id);
      setSensors(sensorsData);
    } catch (error) {
      console.error("Error fetching Sensors from Backend");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [...selected];
    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setSelected(newSelected);
  };

  const isItemSelected = (id) => selected.indexOf(id) !== -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDeleteSensor = async (id) => {
    try {
      const response = await api.delete(`/sensors/${id}`);
      fetchData();
      if (response.status == 200) {
        handleCloseDelete();
        setShowSuccessDelete(true);
      }
    } catch (error) {
      console.error("Error deleting board:", error);
    }
  };

  // Modal Add Sensor
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (nbOfBoards === 0) {
      setOpenNoBoards(true);
    } else {
      setOpen(true);
    }
  };
  const handleClose = async () => {
    setOpen(false);
    fetchData();
  };
  // Modal Update Sensor
  const [openUpdate, setOpenUpdate] = useState(false);
  const handleOpenUpdate = (sensor) => {
    setSelectedSensor(sensor);
    setOpenUpdate(true);
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
    fetchData();
  };
  // Modal Delete Sensor
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = (sensor) => {
    setSelectedSensor(sensor);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);
  // Modal Sensor Details
  const [openDetails, setOpenDetails] = useState(false);

  const handleCloseDetails = () => setOpenDetails(false);

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    desc: "",
    unitType: "",
    range: "",
    connectTo: "",
    api: "",
  });

  //Search Sensor By Name
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    if (value === "") {
      fetchData();
    } else {
      const filteredSensors = sensors.filter((sensor) =>
        sensor.name.toLowerCase().includes(value)
      );
      setSensors(filteredSensors);
    }
  };

  const onBoardAdded = (isAdded) => {
    fetchDefaultBoard();
  };

  return (
    <>
      {/* Table */}
      <div className="justify-center mx-[32px] mt-[36px]">
        <div
          className={`flex flex-col md:flex-row justify-between items-start my-[20px] `}
        >
          <h2
            className={`text-[25px] font-[600] ${
              theme === "dark" ? "text-white" : "text-black"
            }`}
          >
            Sensors
          </h2>
          <div className="flex flex-col md:flex-row gap-2 mt-2 w-full md:justify-end md:items-end ">
            <div
              className={`rounded-[10px] h-[45px] flex border sm:flex md:max-w-[300px] ${
                theme === "dark"
                  ? "bg-[#1D221C] border-[#1D221C]"
                  : "bg-white border-[#E5E5E5]"
              }`}
            >
              <input
                type="text"
                placeholder="Search"
                className={` pl-[32px] text-[16px]  pr-4  rounded-[10px] focus:outline-none w-full
                ${theme === "dark" ? "bg-[#1D221C] text-white" : "bg-white"}`}
                onChange={handleSearch}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="29"
                viewBox="0 0 24 25"
                fill="none"
                className={`mr-5 mt-2  bg-[#1D221C]
                ${theme === "dark" ? "bg-[#1D221C]" : "bg-white"}
                `}
              >
                <path
                  d="M11 19.5C15.4183 19.5 19 15.9183 19 11.5C19 7.08172 15.4183 3.5 11 3.5C6.58172 3.5 3 7.08172 3 11.5C3 15.9183 6.58172 19.5 11 19.5Z"
                  stroke={
                    theme === "dark" &&
                    localStorage.getItem("theme-color") === "#000000"
                      ? "#fff"
                      : localStorage.getItem("theme-color") || "#168004"
                  }
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 21.5L16.65 17.15"
                  stroke={
                    theme === "dark" &&
                    localStorage.getItem("theme-color") === "#000000"
                      ? "#fff"
                      : localStorage.getItem("theme-color") || "#168004"
                  }
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="flex md:items-end md:justify-end">
              <GeneralButton
                onClick={handleOpen}
                backgroundColor={
                  localStorage.getItem("theme-color") || userData.themeColor
                }
                className="mt-8 md:mt-0"
              >
                Create
              </GeneralButton>
            </div>
          </div>
        </div>
        <Paper
          sx={{
            width: "100%",
            border: "none",
            borderRadius: "20px",
          }}
        >
          <TableContainer
            sx={{ maxHeight: 863, border: "none", marginTop: "10px" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <LoadingIndicator />
                ) : sensors.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns.length + 1} align="center">
                      <div
                        className="text-[20px] font-semibold flex justify-center "
                        style={{
                          color:
                            localStorage.getItem("theme-color") ||
                            userData.themeColor,
                        }}
                      >
                        No sensors found.
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  sensors
                    .slice(
                      page * sensorsPerPage,
                      page * sensorsPerPage + sensorsPerPage
                    )
                    .map((row) => (
                      <TableRow
                        key={row.id}
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected(row.id)}
                        selected={isItemSelected(row.id)}
                      >
                        <TableCell padding="checkbox"></TableCell>
                        {columns.map((column) =>
                          column.id === "is_realtime" ? (
                            <TableCell key={column.id}>
                              <div className="flex">
                                <div
                                  className={`rounded-[19px] text-[16px] font-[500] px-[14px] py-[5px] min-w-[100px] flex justify-center ${
                                    row[column.id] === true
                                      ? "bg-green_label text-dark_green"
                                      : "bg-[#FFC5C5] text-[#C30D0D]"
                                  }`}
                                >
                                  {row[column.id] === true
                                    ? "Active"
                                    : "Inactive"}
                                </div>
                              </div>
                            </TableCell>
                          ) : column.id === "board" ? (
                            <TableCell key={column.id}>
                              {getBoardNameById(row[column.id], userBoards)}
                            </TableCell>
                          ) : column.id === "action" ? (
                            <TableCell key={column.id}>
                              <div className="flex gap-[8px]">
                                <button
                                  className="bg-icon_bg p-[6px] rounded-[4px] w-[26px] h-[26px] flex justify-center items-center"
                                  type="button"
                                  onClick={() => {
                                    handleOpenUpdate(row);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                  >
                                    <path
                                      d="M7 11.3333H11.875"
                                      stroke={
                                        theme === "dark" ? "#FFFFFF" : "#525256"
                                      }
                                      strokeWidth="1.21875"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M9.4375 2.39587C9.65299 2.18039 9.94525 2.05933 10.25 2.05933C10.4009 2.05933 10.5503 2.08905 10.6897 2.14679C10.8291 2.20454 10.9558 2.28918 11.0625 2.39587C11.1692 2.50257 11.2538 2.62924 11.3116 2.76865C11.3693 2.90806 11.399 3.05748 11.399 3.20837C11.399 3.35927 11.3693 3.50869 11.3116 3.6481C11.2538 3.78751 11.1692 3.91418 11.0625 4.02087L4.29167 10.7917L2.125 11.3334L2.66667 9.16671L9.4375 2.39587Z"
                                      stroke={
                                        theme === "dark" ? "#FFFFFF" : "#525256"
                                      }
                                      strokeWidth="1.21875"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleOpenDelete(row);
                                  }}
                                  className="bg-icon_bg p-[6px] rounded-[4px] w-[26px] h-[26px] flex justify-center items-center"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                  >
                                    <path
                                      d="M4.50049 14.25C4.50049 15.075 5.17549 15.75 6.00049 15.75H12.0005C12.8255 15.75 13.5005 15.075 13.5005 14.25V6.75C13.5005 5.925 12.8255 5.25 12.0005 5.25H6.00049C5.17549 5.25 4.50049 5.925 4.50049 6.75V14.25ZM13.5005 3H11.6255L11.093 2.4675C10.958 2.3325 10.763 2.25 10.568 2.25H7.43299C7.23799 2.25 7.04299 2.3325 6.90799 2.4675L6.37549 3H4.50049C4.08799 3 3.75049 3.3375 3.75049 3.75C3.75049 4.1625 4.08799 4.5 4.50049 4.5H13.5005C13.913 4.5 14.2505 4.1625 14.2505 3.75C14.2505 3.3375 13.913 3 13.5005 3Z"
                                      fill={
                                        theme === "dark" ? "#FFFFFF" : "#525256"
                                      }
                                    />
                                  </svg>
                                </button>
                              </div>
                            </TableCell>
                          ) : (
                            <TableCell key={column.id}>
                              {row[column.id]}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="flex justify-center  items-center my-[20px] p-2 mx-[35px]">
            <Pagination
              count={Math.ceil(sensors.length / sensorsPerPage)}
              page={page + 1}
              shape="rounded"
              variant="outlined"
              onChange={(event, value) => {
                handleChangePage(event, value - 1);
              }}
            />
          </div>
        </Paper>
        {/* Add Sensor */}
        <AddSensorModal
          open={open}
          handleClose={handleClose}
          style={style}
          userBoards={userBoards}
          defaultBoardId={defaultBoardId}
        />
        {/* Should add a board modal */}
        <AddBoardWarningModal
          open={openNoBoards}
          handleClose={() => setOpenNoBoards(false)}
          style={style}
          onBoardAdded={onBoardAdded}
        />
        {/* Update Sensor */}
        <UpdateSensorModal
          openUpdate={openUpdate}
          handleCloseUpdate={handleCloseUpdate}
          style={style}
          sensor={selectedSensor}
        />
        {/* Delete Sensor */}
        <DeleteSensorModal
          openDelete={openDelete}
          handleCloseDelete={handleCloseDelete}
          style={style}
          handleDeleteSensor={handleDeleteSensor}
          sensorId={selectedSensor.id}
        />

        <CustomSnackbar
          openSnackbar={showSuccessDelete}
          closeSnackbar={() => setShowSuccessDelete(false)}
          message="Sensor deleted successfully"
        />
        {/* Details */}
        {sensors.length > 0 && selectedSensor != [] && (
          <DetailsSensorModal
            openDetails={openDetails}
            handleCloseDetails={handleCloseDetails}
            style={style}
            sensor={selectedSensor}
          />
        )}
      </div>
    </>
  );
};

export default SensorsPage;
