import axios from "axios";
import api from "../Intercepters/AuthIntercepter";

const fetchDashboards = async (userId) => {
  try {
    // Get user's boards list
    const url = `/dashboards/by-user/${userId}`;
    const response = await api.get(url);
    return response.data.dashboards;
  } catch (error) {
    console.error("Error fetching dashboards:", error);
    throw error;
  }
};
const addUserForDashboard = async (email, dashboardId) => {
  const url = `/dashboards/add-member/`;
  const response = await api.post(url, { email, dashboardId });
  if (response.data.message == "User not found") {
    return "user-not-found";
  } else if (response.status == 200) {
    return "added-successfully";
  } else if (response.status == 404) {
    return "dashboard-not-found";
  } else if (response.status == 400) {
    return "user-already-exists";
  } else {
    return "unexpected-error";
  }
};
const getUsersForDashboard = async (dashboardId) => {
  const response = await api.get(`/dashboards/get-members/${dashboardId}`);
  if (response.status === 400) {
    return response.data.message;
  } else {
    return response.data.dashboardUsers;
  }
};
const getUserByEmail = async (email) => {
  try {
    const response = await api.get(`/user/user-by-email/${email}`);
    return response.data.user;
  } catch (error) {
    console.error("Error fetching user by email:", error);
    throw error;
  }
};
const removeUserFromDashboard = async (email, dashboardId) => {
  try {
    const url = `/dashboards/remove-member`;
    const response = await api.post(url, { email, dashboardId });
    return response.data;
  } catch (error) {
    console.error("Error removing user from dashboard:", error);
    throw error;
  }
};
const updateDashboard = async (dashboardId, data) => {
  try {
    const response = await api.put(`/dashboards/${dashboardId}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export {
  fetchDashboards,
  addUserForDashboard,
  getUsersForDashboard,
  getUserByEmail,
  removeUserFromDashboard,
  updateDashboard,
};
