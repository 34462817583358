import api from "../Intercepters/AuthIntercepter";

const fetchLastNotifications = async (userId) => {
  try {
    // Get user's last notifications
    const url = `/notifications/last-notifications/${userId}`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching last notifications:", error);
    throw error;
  }
};

const clearNotifications = async (userId) => {
  try {
    const url = `/notifications/clear-by-user/${userId}`;
    const response = await api.delete(url);
    return response.status;
  } catch (error) {
    console.error(
      "Error when trying to clear notifications for the user:",
      error
    );
    throw error;
  }
};

export { fetchLastNotifications, clearNotifications };
