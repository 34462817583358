import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import delete_icon from "../../assets/images/delete-icon.svg";
import { addWidgetToCustomDashboard } from "../../services/customDashboardService";
import { useUser } from "../../state/UserContext";
import home_icon from "../../assets/images/home-icon.svg";
import { ThemeContext } from "../../state/ThemeContext";
import crudIcon from "../../assets/images/crud-icon.svg";
import CustomSnackbar from "../CustomSnackbar";
import DotsDark from "../../assets/images/dark-dots.svg";
import api from "../../Intercepters/AuthIntercepter";

const AccelerometerWidget = ({
  value,
  color,
  sensor,
  type,
  widget,
  onDelete,
  updatedAtArray,
  boardName,
  width,
  fromHome,
}) => {
  const [themeColor, setThemeColor] = useState("");
  const theme = useContext(ThemeContext).theme;

  useEffect(() => {
    const currentColor = localStorage.getItem("theme-color");
    setThemeColor(currentColor);
  }, []);
  const { userData } = useUser();

  const [series, setSeries] = useState([
    {
      name: "X",
      data: [],
    },
    {
      name: "Y",
      data: [],
    },
    {
      name: "Z",
      data: [],
    },
  ]);
  useEffect(() => {
    const xData = [];
    const yData = [];
    const zData = [];

    value.forEach((item) => {
      xData.push(parseFloat(item.X));
      yData.push(parseFloat(item.Y));
      zData.push(parseFloat(item.Z));
    });
    setSeries([
      {
        name: "X",
        data: xData,
      },
      {
        name: "Y",
        data: yData,
      },
      {
        name: "Z",
        data: zData,
      },
    ]);
  }, [value]);

  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      foreColor: theme === "dark" ? "#ffffff" : "#000000",
    },
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    legend: {
      position: "top",
    },
    xaxis: {
      categories: updatedAtArray.map((date) =>
        new Date(date).toLocaleTimeString()
      ),
      title: { text: ` ${new Date(updatedAtArray[0]).toDateString()}` },
      tickAmount: 3,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return parseInt(value).toString();
        },
      },
    },
  };
  if (type === "line") {
    options.stroke = {
      width: 3,
      curve: "smooth",
    };
  } else if (type === "bar") {
    options.plotOptions = {
      bar: {
        borderRadius: 10,
        barWidth: 50,
        columnWidth: "70%",
      },
    };
  }
  const [dropdownStates, setDropdownStates] = useState(false);
  const toggleDropdown = () => {
    setDropdownStates(!dropdownStates);
  };

  const handleDeleteClick = async (id) => {
    try {
      await api.delete(`/widgets/${widget}`);
      onDelete(widget);
    } catch (error) {
      console.error("Error deleting widget:", error);
    }
  };
  const [successMessage, setSuccessMessage] = useState(false);

  const handleAddWidgetToDashboard = async () => {
    try {
      const userId = userData.id;
      const widgetId = widget;
      await addWidgetToCustomDashboard(widgetId, userId);
      setSuccessMessage(true);
    } catch (error) {
      console.error("Error adding widget to custom dashboard:", error);
      throw error;
    }
  };

  return (
    <div
      className={`spline-area-chart rounded-[12px] shadow-md  bg-white dark:bg-dark2 p-[15px]`}
      // style={{ border: "1px solid rgba(0, 0, 0, 0.15)" }}
    >
      <div className="flex flex-row justify-between items-center px-[20px] p-7">
        {fromHome ? (
          <div className="flex flex-col lg:flex-row lg:items-center w-full">
            <div className="flex flex-col lg:flex-row lg:items-center mb-4 lg:mb-0 lg:mr-20">
              <div
                className={`text-lg font-medium ${
                  theme === "dark" ? "text-white" : "text-black"
                }`}
              >
                Board:
              </div>
              <p
                className={`text-base ${
                  theme === "dark" ? "text-white" : "text-black"
                } lg:ml-2`}
              >
                {boardName}
              </p>
            </div>
            <div className="flex flex-col lg:flex-row  lg:justify-end xl:ml-[12rem] lg:mx-auto">
              <div
                className={`text-lg font-medium ${
                  theme === "dark" ? "text-white" : "text-black"
                }`}
              >
                Sensor:
              </div>
              <p
                className={`text-base ${
                  theme === "dark" ? "text-white" : "text-black"
                } lg:ml-2`}
              >
                Accelerometer
              </p>
            </div>
          </div>
        ) : (
          <div
            className={`text-base ${
              theme === "dark" ? "text-white" : "text-black"
            }`}
          >
            Accelerometer
          </div>
        )}

        {!fromHome && (
          <div className="relative">
            <img
              src={theme === "dark" ? DotsDark : crudIcon}
              alt="edit-image"
              className="w-9 p-2 cursor-pointer"
              onClick={() => toggleDropdown()}
            />

            {dropdownStates && (
              <div
                className={`absolute top-10 right-0 shadow-md w-60 rounded-lg z-50 p-2 ${
                  theme === "dark" ? "text-white bg-[#262626]" : "bg-white"
                }`}
              >
                <div
                  className="cursor-pointer p-2 flex"
                  onClick={handleDeleteClick}
                >
                  <div className="rounded-[8px] bg-[#D2D2D240] p-2">
                    <img src={delete_icon} alt="delete-icon" />
                  </div>
                  <span className="ml-4 mt-1">Delete</span>
                </div>

                <div
                  className="cursor-pointer p-2 flex"
                  onClick={handleAddWidgetToDashboard}
                >
                  <div className="rounded-[8px] bg-[#D2D2D240] p-2">
                    <img src={home_icon} alt="home-icon" />
                  </div>
                  <span className="ml-4 mt-1">Add To Home Page </span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <ReactApexChart
        options={options}
        series={series}
        type={type}
        height={350}
        width={width}
      />
      <CustomSnackbar
        openSnackbar={successMessage}
        closeSnackbar={() => {
          setSuccessMessage(false);
        }}
        message="Widget added to your home page successfully!"
      />
    </div>
  );
};

export default AccelerometerWidget;
