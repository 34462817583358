import api from "../Intercepters/AuthIntercepter";

const fetchBoards = async (userId) => {
  try {
    // Get user's boards list
    const url = `/boards/by-user/${userId}`;
    const response = await api.get(url);
    return response.data.boards;
  } catch (error) {
    throw error;
  }
};

const fetchNbOfBoards = async (userId) => {
  try {
    // Get user's boards list
    const url = `/boards/by-user/${userId}`;
    const response = await api.get(url);
    return response.data.boards.length;
  } catch (error) {
    throw error;
  }
};

const getBoardNameById = (boardId, users) => {
  const board = users.find((item) => item.id === boardId);
  return board ? board.name : null;
};

const getBoardById = async (boardId) => {
  try {
    const url = `/boards/${boardId}`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export { fetchBoards, getBoardNameById, getBoardById, fetchNbOfBoards };
