import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ThemeContext } from "../../../state/ThemeContext";

const UVChartConsole = ({ value, color }) => {
  const theme = useContext(ThemeContext).theme;

  const [uvData, setUvData] = useState([]);

  useEffect(() => {
    setUvData((prevUvData) => [...prevUvData, value]);
  }, [value]);

  const options = {
    chart: {
      id: "realtime",
      height: 350,
      type: "line",
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000,
        },
      },
      foreColor: theme === "dark" ? "#ffffff" : "#000000",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "stepline",
    },
    xaxis: {
      categories: uvData.map((_, index) => index + 1),
    },
    yaxis: {
      min: 0,
      // max: 2,
      labels: {
        formatter: function (value) {
          return parseInt(value).toString();
        },
      },
    },
    legend: {
      show: false,
    },
    colors: [color],
  };

  return (
    <div
      className={`spline-area-chart rounded-[12px] shadow-md  bg-white dark:bg-dark2 p-[15px]`}
    >
      <div className="flex justify-between">
        <div className="flex items-center gap-3">
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_2181_32756"
              maskUnits="userSpaceOnUse"
              x="5"
              y="5"
              width="34"
              height="34"
            >
              <path
                d="M5.5 5.5H38.5V22H28.1875C26.5465 22 24.9727 22.6519 23.8123 23.8123C22.6519 24.9727 22 26.5465 22 28.1875V38.5H5.5V5.5Z"
                fill={color}
              />
            </mask>
            <g mask="url(#mask0_2181_32756)">
              <g clip-path="url(#clip0_2181_32756)">
                <path
                  d="M22 29.2188C25.9868 29.2188 29.2188 25.9868 29.2188 22C29.2188 18.0132 25.9868 14.7812 22 14.7812C18.0132 14.7812 14.7812 18.0132 14.7812 22C14.7812 25.9868 18.0132 29.2188 22 29.2188Z"
                  stroke={color}
                  strokeWidth="1.28906"
                  stroke-miterlimit="10"
                />
                <path
                  d="M22 10.8023V6.53125M22 37.4688V33.1977M29.9234 14.0766L32.9313 11.0687M11.0687 32.9313L14.0852 29.9148M14.0852 14.068L11.0773 11.0602M32.9484 32.9313L29.932 29.9148M10.8023 22H6.53125M37.4688 22H33.1977"
                  stroke={color}
                  strokeWidth="1.28906"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
              </g>
              <path
                d="M21.8281 29.0469V28.1875C21.8281 26.5009 22.4981 24.8834 23.6907 23.6907C24.8834 22.4981 26.5009 21.8281 28.1875 21.8281H29.0469"
                stroke={color}
                strokeWidth="1.28906"
                stroke-miterlimit="10"
              />
            </g>
            <path
              d="M29.0211 33.3438C28.1961 33.3438 27.563 33.149 27.1219 32.7594C26.6807 32.3698 26.4573 31.8169 26.4516 31.1008V27.1562H28.0242V31.1094C28.0242 31.7396 28.3594 32.0547 29.0297 32.0547C29.7 32.0547 30.0323 31.7396 30.0266 31.1094V27.1562H31.5992V31.1008C31.5992 31.8284 31.3758 32.3841 30.9289 32.768C30.482 33.1518 29.8461 33.3438 29.0211 33.3438ZM35.5266 29.6055L36.2312 27.1562H37.8297L35.8875 33.1547H34.2203L32.2953 27.1562H33.9109L34.6156 29.5969L35.0797 31.4875L35.5266 29.6055Z"
              fill={color}
            />
            <defs>
              <clipPath id="clip0_2181_32756">
                <rect
                  width="32.2266"
                  height="32.2266"
                  fill={color}
                  transform="translate(5.88672 5.88672)"
                />
              </clipPath>
            </defs>
          </svg>
          <span className="text-[16px] font-[400] font-Roboto dark:text-white">
            UV
          </span>
        </div>
        <div className="flex items-baseline gap-1"></div>
      </div>
      <ReactApexChart
        options={options}
        series={[{ data: uvData }]}
        type="line"
      />
    </div>
  );
};

export default UVChartConsole;
