import api from "../Intercepters/AuthIntercepter";

const getLogsByUser = async (userId) => {
  try {
    const response = await api.get(`/activity-logs/by-user/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching logs:", error.message);
    throw error;
  }
};

const clearLogsByUser = async (userId) => {
  try {
    const response = await api.delete(`/activity-logs/clear-by-user/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error clearing logs:", error.message);
    throw error;
  }
};

export { getLogsByUser, clearLogsByUser };
