import React, { useEffect, useState } from "react";
import { Modal, Box } from "@mui/material";
import { useUser } from "../../../state/UserContext";
import IOSSwitch from "../../IosSwitch";
import { updateCustomDashboardByUser } from "../../../services/customDashboardService";

const CustomizeDashboardModal = ({
  open,
  handleClose,
  style,
  initialIsCurrentDateVisible,
  initialIsCurrentLocationVisible,
  initialIsActiveBoardsVisible,
  initialIsInActiveBoardsVisible,
  initialIsActiveSensorsVisible,
  initialIsInActiveSensorsVisible,
  initialIsBoardsVisible,
  initialIsWidgetsVisible,
  initialIsLogsVisible,
  onSave,
}) => {
  const { userData } = useUser();
  const handleCloseModal = () => {
    handleClose();
  };
  const [isCurrentDateVisible, setIsCurrentDateVisible] = useState(
    initialIsCurrentDateVisible
  );
  const [isCurrentLocationVisible, setIsCurrentLocationVisible] = useState(
    initialIsCurrentLocationVisible
  );
  const [isActiveBoardsVisible, setIsActiveBoardsVisible] = useState(
    initialIsActiveBoardsVisible
  );
  const [isInActiveBoardsVisible, setIsInActiveBoardsVisible] = useState(
    initialIsInActiveBoardsVisible
  );
  const [isActiveSensorsVisible, setIsActiveSensorsVisible] = useState(
    initialIsActiveSensorsVisible
  );
  const [isInActiveSensorsVisible, setIsInActiveSensorsVisible] = useState(
    initialIsInActiveSensorsVisible
  );
  const [isBoardsVisible, setIsBoardsVisible] = useState(
    initialIsBoardsVisible
  );
  const [isWidgetsVisible, setIsWidgetsVisible] = useState(
    initialIsWidgetsVisible
  );
  const [isLogsVisible, setIsLogsVisible] = useState(initialIsLogsVisible);
  useEffect(() => {
    setIsCurrentDateVisible(initialIsCurrentDateVisible);
    setIsCurrentLocationVisible(initialIsCurrentLocationVisible);
    setIsActiveBoardsVisible(initialIsActiveBoardsVisible);
    setIsInActiveBoardsVisible(initialIsInActiveBoardsVisible);
    setIsActiveSensorsVisible(initialIsActiveSensorsVisible);
    setIsInActiveSensorsVisible(initialIsInActiveSensorsVisible);
    setIsBoardsVisible(initialIsBoardsVisible);
    setIsWidgetsVisible(initialIsWidgetsVisible);
    setIsLogsVisible(initialIsLogsVisible);
  }, [
    initialIsCurrentDateVisible,
    initialIsCurrentLocationVisible,
    initialIsActiveBoardsVisible,
    initialIsInActiveBoardsVisible,
    initialIsActiveSensorsVisible,
    initialIsInActiveSensorsVisible,
    initialIsBoardsVisible,
    initialIsWidgetsVisible,
    initialIsLogsVisible,
  ]);
  const handleConfirm = async () => {
    try {
      await updateCustomDashboardByUser(userData.id, {
        currentDate: isCurrentDateVisible,
        currentLocation: isCurrentLocationVisible,
        activeBoards: isActiveBoardsVisible,
        inactiveBoards: isInActiveBoardsVisible,
        activeSensors: isActiveSensorsVisible,
        inactiveSensors: isInActiveSensorsVisible,
        myBoards: isBoardsVisible,
        widgets_visible: isWidgetsVisible,
        activityLogs: isLogsVisible,
      });
      onSave(
        isCurrentDateVisible,
        isCurrentLocationVisible,
        isActiveBoardsVisible,
        isInActiveBoardsVisible,
        isActiveSensorsVisible,
        isInActiveSensorsVisible,
        isBoardsVisible,
        isWidgetsVisible,
        isLogsVisible
      );
      handleCloseModal();
    } catch (error) {
      console.error("Error updating customized dashboard:", error);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Box sx={style}>
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-dark_black font-[700] text-[24px]">
              Customize Dashboard
            </h3>

            <button type="button" onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="24"
                viewBox="0 0 27 24"
                fill="none"
              >
                <path
                  d="M20.25 5.93091L6.75 17.3185"
                  stroke="#525256"
                  strokeWidth="2.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.75 5.93091L20.25 17.3185"
                  stroke="#525256"
                  strokeWidth="2.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            className=" text-[16px] font-[400] mt-3"
            style={{
              color:
                localStorage.getItem("themeMode") === "dark"
                  ? "#ffffff"
                  : "#475467",
            }}
          >
            Customize Your Home Page View: Choose what you want to see or hide.
          </div>
          <div className="flex  flex-col gap-2  mt-5 ">
            <div className="flex justify-between">
              <p className="text-[16px] font-[500] mt-2">Active Boards</p>
              <IOSSwitch
                sx={{ m: 1 }}
                checked={isActiveBoardsVisible}
                onChange={(e) => setIsActiveBoardsVisible(e.target.checked)}
              />
            </div>
            <div className="flex justify-between">
              <p className="text-[16px] font-[500] mt-2">Inactive Boards</p>
              <IOSSwitch
                sx={{ m: 1 }}
                checked={isInActiveBoardsVisible}
                onChange={(e) => setIsInActiveBoardsVisible(e.target.checked)}
              />
            </div>
            <div className="flex justify-between">
              <p className="text-[16px] font-[500] mt-2">Active Sensors</p>
              <IOSSwitch
                sx={{ m: 1 }}
                checked={isActiveSensorsVisible}
                onChange={(e) => setIsActiveSensorsVisible(e.target.checked)}
              />
            </div>
            <div className="flex justify-between">
              <p className="text-[16px] font-[500] mt-2">Inactive Sensors</p>
              <IOSSwitch
                sx={{ m: 1 }}
                checked={isInActiveSensorsVisible}
                onChange={(e) => setIsInActiveSensorsVisible(e.target.checked)}
              />
            </div>
            <div className="flex justify-between">
              <p className="text-[16px] font-[500] mt-2">Activity Logs</p>
              <IOSSwitch
                sx={{ m: 1 }}
                checked={isLogsVisible}
                onChange={(e) => setIsLogsVisible(e.target.checked)}
              />
            </div>

            <div className="flex justify-between">
              <p className="text-[16px] font-[500] mt-2"> My Boards</p>
              <IOSSwitch
                sx={{ m: 1 }}
                checked={isBoardsVisible}
                onChange={(e) => setIsBoardsVisible(e.target.checked)}
              />
            </div>
            <div className="flex justify-between">
              <p className="text-[16px] font-[500] mt-2">My Widgets</p>
              <IOSSwitch
                sx={{ m: 1 }}
                checked={isWidgetsVisible}
                onChange={(e) => setIsWidgetsVisible(e.target.checked)}
              />
            </div>
          </div>
          <div>
            <div className="flex justify-end mt-[20px] mb-[45px]">
              <div className="flex flex-wrap gap-[16px] modal-cancel w-full lg:w-auto">
                <button
                  className="bg-[#D9D9D9] rounded-[10px] px-[16px] py-[10px] w-full lg:w-28 text-black"
                  type="button"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  className=" text-white rounded-[10px] px-[16px] py-[10px] w-full lg:w-28 "
                  type="button"
                  onClick={handleConfirm}
                  style={{
                    backgroundColor:
                      localStorage.getItem("theme-color") ||
                      userData.themeColor,
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CustomizeDashboardModal;
