import axios from "axios";
import api from "../Intercepters/AuthIntercepter";

const clearWidgetFromHome = async (widgetId, userId) => {
  try {
    const response = await api.post(`/custom-dashboard/remove-widget`, {
      widgetId,
      userId,
    });
    return response.status == 200;
  } catch (error) {
    console.error("error when trying to clear the widget from home page");
  }
};
const getWidgetById = async (widgetId) => {
  try {
    const response = await api.get(`/widgets/${widgetId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching widget by ID:", error);
    throw error;
  }
};
export { clearWidgetFromHome };
