import React, { useContext, useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import { ThemeContext } from "../../../state/ThemeContext";
import { useUser } from "../../../state/UserContext";
import { addUserForDashboard, fetchDashboards } from "../../../services/dashboardService";

const AddMemberModal = ({ open, handleClose, style }) => {
    const { userData } = useUser();
    const [email, setEmail] = useState("");
    const [dashboardId, setDashboardId] = useState(""); 
    const theme = useContext(ThemeContext).theme;
    const [errors, setErrors] = useState({});
    const [dashboards, setDashboards] = useState([]);

    const handleCloseModal = () => {
        handleClose();
    };

    const fetchData = async () => {
        try {
            const dashboardsData = await fetchDashboards(userData.id);
            setDashboards(dashboardsData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [userData.id]); 

    const handleConfirm = async () => {
        try {
            await addUserForDashboard(email, dashboardId); 
            handleCloseModal();
        } catch (error) {
            console.error("Error adding user to dashboard:", error);
        }
    };

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleDashboardChange = (e) => {
        setDashboardId(e.target.value); 
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal"
            >
                <Box sx={style}>
                    <div className="flex justify-between">
                        <h3 className="text-dark_black  font-[600] text-[22px] md:font-[700] lg:text-[28px]">
                            Add New Member
                        </h3>
                        <button type="button" onClick={handleClose}>
                            {/* Close button */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="27"
                                height="24"
                                viewBox="0 0 27 24"
                                fill="none"
                            >
                                <path
                                    d="M20.25 5.93091L6.75 17.3185"
                                    stroke="#525256"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6.75 5.93091L20.25 17.3185"
                                    stroke="#525256"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                    {/* Form */}
                    <div >
                        <form className="flex flex-col mt-4">
                            <label
                                htmlFor="name"
                                className={`my-3 text-[15px] font-[500] text-dark_black`}
                            >
                                Email address
                            </label>
                            <input
                                placeholder="Enter member email"
                                label="Email"
                                name="Email"
                                type="text"
                                value={email}
                                onChange={handleChange}
                                className={`rounded-[8px]  mb-[10px] px-3 focus:outline-none h-[40px] ${theme === "dark"
                                    ? "bg-[#4A4A4A]"
                                    : "bg-white border border-[#D0D5DD]"
                                    } `}
                            />
                            <span className="text-red-500">{errors.name}</span>

                            <label
                                htmlFor="dashboard"
                                className={`my-3 text-[15px] font-[500] text-dark_black`}
                            >
                                Dashboard
                            </label>
                            <select
                                value={dashboardId} 
                                onChange={handleDashboardChange} 
                                className={`rounded-[8px] mt-2 mb-[10px] border border-[#E5E5E5]  focus:outline-none h-[40px] ${theme === "dark"
                                    ? "bg-[#4A4A4A] text-white border-none"
                                    : "bg-white"
                                    }`}
                            >
                                {dashboards.map((dashboard) => (
                                    <option key={dashboard.id} value={dashboard.id}>
                                        {dashboard.name}
                                    </option>
                                ))}
                            </select>
                            <div className="flex justify-end mt-[20px] mb-[45px]">
                                <div className="flex flex-col lg:flex-row gap-[16px] modal-cancel w-full">
                                    <button
                                        className="bg-[#D9D9D9] rounded-[10px] px-[16px] py-[10px] w-full text-black"
                                        type="button"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="text-white rounded-[10px] px-[16px] py-[10px] w-full "
                                        type="button"
                                        onClick={handleConfirm}
                                        style={{
                                            backgroundColor:
                                                localStorage.getItem("theme-color") || userData.themeColor,
                                        }}
                                    >
                                        Invite
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default AddMemberModal;
