import axios from "axios";
import api from "../Intercepters/AuthIntercepter";

const addWidgetToCustomDashboard = async (widgetId, userId) => {
  try {
    const url = `/custom-dashboard/add-widget`;
    const response = await api.post(url, { widgetId, userId });
    return response.data;
  } catch (error) {
    console.error("Error adding widget to custom dashboard:", error);
    throw error;
  }
};
const getWidgetsFromCustomDashboard = async (userId) => {
  try {
    const response = await api.get(`/custom-dashboard/widgets/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching widgets from custom dashboard:", error);
    throw error;
  }
};

const getCustomDashByUser = async (userId) => {
  try {
    const response = await api.get(`/custom-dashboard/by-user/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching customized dashboard:", error);
    throw error;
  }
};

const getAllCustomDashboards = async () => {
  try {
    const response = await api.get(`/custom-dashboard`);
    return response.data.customizedDashboards;
  } catch (err) {
    console.error("Error fetching all customized dashboards:", err);
    throw err;
  }
};

const updateCustomDashboardByUser = async (userId, updates) => {
  try {
    await api.put(`/custom-dashboard/by-user/${userId}`, updates);
  } catch (err) {
    console.error("Error updating customized dashboard:", err);
    throw err;
  }
};

export {
  addWidgetToCustomDashboard,
  getWidgetsFromCustomDashboard,
  getCustomDashByUser,
  getAllCustomDashboards,
  updateCustomDashboardByUser,
};
