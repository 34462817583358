import React, { useContext, useEffect, useState } from 'react';
import { Modal, Box, Switch, FormControlLabel, styled } from '@mui/material';
import { ThemeContext } from '../../../state/ThemeContext';
import api from '../../../Intercepters/AuthIntercepter';
const DetailsSensorModal = ({ openDetails, handleCloseDetails, style, sensor }) => {
  const [boardName, setBoardName] = useState("");
  const theme = useContext(ThemeContext).theme;


  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 52,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(25px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#168004",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const getBoardById = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token is missing");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + token
  
      };

        const url = `/boards/${sensor?.board}`;
        const response = await api.get(url);
        setBoardName(response.data['name']);
      
    }
    catch (error) {
      console.error('Error throwed when trying to get board by id', error);
    }
  }
  useEffect(() => {
    if (sensor && sensor.board) {

      getBoardById();
    }
  
  }, [sensor]);

  return (
    <Modal
      open={openDetails}
      onClose={handleCloseDetails}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <Box sx={style} className="modal-content">
        <div className="flex justify-between items-center mb-2">
          <h3 className={`font-[700] text-[28px]  ${theme === "dark" ? "text-white" : "text-black"}`}>
            Sensor details
          </h3>
          <button type="button" onClick={handleCloseDetails}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="24"
              viewBox="0 0 27 24"
              fill="none"
            >
              <path
                d="M20.25 5.93091L6.75 17.3185"
                stroke="#525256"
                strokeWidth="2.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.75 5.93091L20.25 17.3185"
                stroke="#525256"
                strokeWidth="2.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <div className="bg-[#EFF0EF] mb-[15px]" style={{ height: "2px" }} />

        <div className="flex flex-col">
          {/* Sensor details */}
          <p className={`mb-[37px] font-[500] font-Inter text-[18px]  ${theme === "dark" ? "text-white" : "text-black"}`}>
            Name : {sensor.name}
          </p>
          <p className={`mb-[37px] font-[500] font-Inter text-[18px]  ${theme === "dark" ? "text-white" : "text-black"}`}>
            Type : {sensor.sensor_type}
          </p>
          <p className={`mb-[37px] font-[500] font-Inter text-[18px] ${theme === "dark" ? "text-white" : "text-black"} `}>
            Description : {sensor.description}
          </p>
          <p className={`mb-[37px] font-[500] font-Inter text-[18px] ${theme === "dark" ? "text-white" : "text-black"}`}>
            Status : {sensor.is_realtime == true ? 'Active' : 'Inactive'}
          </p>
          <p className={`mb-[37px] font-[500] font-Inter text-[18px] ${theme === "dark" ? "text-white" : "text-black"} `}>
            Unit : {sensor.unit}
          </p>
          <p className={`mb-[37px] font-[500] font-Inter text-[18px] text-[#344056] ${theme === "dark" ? "text-white" : "text-black"}`}>
            Connect To : {boardName}
          </p>

          <div className={`flex flex-wrap ${theme === "dark" ? "text-white" : "text-black"}`}>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={!!sensor.is_realtime}
                />
              }
              label="Realtime data"
            />
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={!!sensor.is_severe}
                />
              }
              label="Critical data"
            />
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={!!sensor.is_historical}
                />
              }
              label="Save history data"
            />
          </div>

          {/* Buttons */}
          
          <div className="flex justify-end mt-4 p-9">
            <button
              type="button"
              onClick={handleCloseDetails}
              className="bg-[#D9D9D9] rounded-[10px] px-[16px] py-[10px] mb-2 md:mb-0"
            >
              Cancel
            </button>

          </div>

        </div>
      </Box>
    </Modal>


  )
}
export default DetailsSensorModal;