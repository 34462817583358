import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useUser } from "../../state/UserContext";
import home_icon from "../../assets/images/home-icon.svg";
import { ThemeContext } from "../../state/ThemeContext";
import crudIcon from "../../assets/images/crud-icon.svg";
import delete_icon from "../../assets/images/delete-icon.svg";
import CustomSnackbar from "../CustomSnackbar";
import { addWidgetToCustomDashboard } from "../../services/customDashboardService";
import DotsDark from "../../assets/images/dark-dots.svg";
import api from "../../Intercepters/AuthIntercepter";

const PresenceWidget = ({
  value,
  color,
  sensor,
  grow,
  type,
  fromDashboard,
  text,
  isArray,
  widget,
  onDelete,
  updatedAtArray,
  boardName,
  width,
  fromHome,
}) => {
  const [themeColor, setThemeColor] = useState("");
  const [motionData, setMotionData] = useState([]);
  useEffect(() => {
    const currentColor = localStorage.getItem("theme-color");
    setThemeColor(currentColor);
  }, []);
  const { userData } = useUser();
  const theme = useContext(ThemeContext).theme;

  useEffect(() => {
    value.forEach((item) => {
      motionData.push(JSON.parse(item.presenceVal));
    });
  }, [value]);

  useEffect(() => {
    const newData = value.map((item) => JSON.parse(item.presenceVal));
    setMotionData(newData);
  }, [value]);
  const [dropdownStates, setDropdownStates] = useState(false);
  const toggleDropdown = () => {
    setDropdownStates(!dropdownStates);
  };

  const options = {
    chart: {
      id: "realtime",
      height: 350,
      type: type,
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000,
        },
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: updatedAtArray.map((date) =>
        new Date(date).toLocaleTimeString()
      ),
      title: { text: ` ${new Date(updatedAtArray[0]).toDateString()}` },
      tickAmount: 3,
    },
    yaxis: {
      categories: motionData.map((_, index) => index + 1),
    },
    legend: {
      show: false,
    },
    colors: [color],
  };

  const optionsSingleValue = {
    chart: {
      type: type,
      height: type === "radialBar" ? 350 : 300,
      foreColor: theme === "dark" ? "#ffffff" : "#000000",
    },
    colors: [color],
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        hollow: {
          size: "65%",
        },
        track: {
          background: "#fff",
          color: color,
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#ccc",
            opacity: 1,
            blur: 2,
          },
        },

        dataLabels: {
          name: {
            show: true, // Show label
            offsetY: -10, // Offset label position
            color: color,
            fontWeight: "400",
            formatter: function () {
              return text;
            },
          },
          value: {
            fontSize: "35px",
            fontWeight: "bold",
            color: color,
            show: true,
            formatter: function (val) {
              val = parseInt(val["data"][0]);
              return val.toFixed(1);
            },
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        backgroundColor: color,
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Motion"],
    background: {
      image: "",
      imageWidth: 0,
      imageHeight: 0,
      imageOffsetX: 0,
      imageOffsetY: 0,
    },
  };
  if (type === "line") {
    options.stroke = {
      width: 3,
      curve: "smooth",
    };
  } else if (type === "bar") {
    options.plotOptions = {
      bar: {
        borderRadius: 10,
        barWidth: 50, // Set the bar width to 100 pixels

        columnWidth: "70%", // Adjust the column width as a percentage
      },
    };
  }

  const handleDeleteClick = async (id) => {
    try {
      await api.delete(`/widgets/${widget}`);
      onDelete(widget);
    } catch (error) {
      console.error("Error deleting widget:", error);
    }
  };

  const [successMessage, setSuccessMessage] = useState(false);

  const handleAddWidgetToDashboard = async () => {
    try {
      const userId = userData.id;
      const widgetId = widget;
      await addWidgetToCustomDashboard(widgetId, userId);
      setSuccessMessage(true);
    } catch (error) {
      console.error("Error adding widget to custom dashboard:", error);
      throw error;
    }
  };
  return (
    <div
      className={`spline-area-chart rounded-[12px] shadow-md  bg-white dark:bg-dark2 p-[15px]`}
    >
      <div className="flex flex-row justify-between items-center px-[20px] p-7">
        {fromHome ? (
          <div className="flex flex-col lg:flex-row lg:items-center w-full">
            <div className="flex flex-col lg:flex-row lg:items-center mb-4 lg:mb-0 lg:mr-20">
              <div
                className={`text-lg font-medium ${
                  theme === "dark" ? "text-white" : "text-black"
                }`}
              >
                Board:
              </div>
              <p
                className={`text-base ${
                  theme === "dark" ? "text-white" : "text-black"
                } lg:ml-2`}
              >
                {boardName}
              </p>
            </div>
            <div className="flex flex-col lg:flex-row  lg:justify-end xl:ml-[12rem] lg:mx-auto">
              <div
                className={`text-lg font-medium ${
                  theme === "dark" ? "text-white" : "text-black"
                }`}
              >
                Sensor:
              </div>
              <p
                className={`text-base ${
                  theme === "dark" ? "text-white" : "text-black"
                } lg:ml-2`}
              >
                Motion
              </p>
            </div>
          </div>
        ) : (
          <div
            className={`text-base ${
              theme === "dark" ? "text-white" : "text-black"
            }`}
          >
            Motion
          </div>
        )}

        <div>
          {!fromHome && (
            <div className="relative">
              <img
                src={theme === "dark" ? DotsDark : crudIcon}
                alt="edit-image"
                className="w-9 p-2 cursor-pointer"
                onClick={() => toggleDropdown()}
              />

              {dropdownStates && (
                <div
                  className={`absolute top-10 right-0 shadow-md w-60 rounded-lg z-50 p-2 ${
                    theme === "dark" ? "text-white bg-[#262626]" : "bg-white"
                  }`}
                >
                  <div
                    className="cursor-pointer p-2 flex"
                    onClick={handleDeleteClick}
                  >
                    <div className="rounded-[8px] bg-[#D2D2D240] p-2">
                      <img src={delete_icon} alt="delete-icon" />
                    </div>
                    <span className="ml-4 mt-1">Delete</span>
                  </div>

                  <div
                    className="cursor-pointer p-2 flex"
                    onClick={handleAddWidgetToDashboard}
                  >
                    <div className="rounded-[8px] bg-[#D2D2D240] p-2">
                      <img src={home_icon} alt="home-icon" />
                    </div>
                    <span className="ml-4 mt-1">Add To Home Page </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ReactApexChart
        options={
          type === "bar" || type === "line" ? options : optionsSingleValue
        }
        height={350}
        width={width}
        series={[
          {
            name: sensor,
            // data: (type === "pie" || type === "radialBar") ? [value[value.length - 1]] : [value.map(item => item.presenceVal)]
            data:
              type === "bar" || type === "line"
                ? motionData
                : [motionData[motionData.length - 1]],
          },
        ]}
        type={type}
      />

      <CustomSnackbar
        openSnackbar={successMessage}
        closeSnackbar={() => {
          setSuccessMessage(false);
        }}
        message="Widget added to your home page successfully!"
      />
    </div>
  );
};

export default PresenceWidget;
