import React, { useContext, useEffect, useState } from "react";
import activeNotif from "../../assets/icons/active_icon.svg";
import inactiveNotif from "../../assets/icons/inactive_icon.svg";
import { ThemeContext } from "../../state/ThemeContext";
import { io } from "socket.io-client";
import {
  fetchLastNotifications,
  clearNotifications,
} from "../../services/notificationService";
import { useUser } from "../../state/UserContext";
import realtimeNotif from "../../assets/icons/realtimeicon.svg";

const notificationIcons = {
  active: <img src={activeNotif} className="w-6 h-6" />,
  realtime_data: <img src={realtimeNotif} className="w-6 h-6" />,
  inactive: <img src={inactiveNotif} className="w-6 h-6" />,
};

export default function Notifications({ showNotifications }) {
  const getLastNotifs = async (userId) => {
    const lastNotifications = await fetchLastNotifications(userData.id);
    return lastNotifications;
  };

  const { userData } = useUser();

  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const lastNotifications = await getLastNotifs(userData.id);
        setNotifications(lastNotifications);
      } catch (error) {
        console.error("Error setting notifications:", error);
      }
    };
    fetchNotifications();
  }, [userData.id]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_SERVER_URL}`;
    const socket = io(url);
    socket.on("notification", (notification) => {
      setNotifications((prevNotifications) => [
        notification,
        ...prevNotifications,
      ]);
    });
    return () => {
      socket.disconnect();
    };
  }, []);
  const handleClearNotifs = async () => {
    const response = await clearNotifications(userData.id);
    setNotifications([]);
  };
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className={`absolute top-20 right-4 lg:right-20 w-[300px] max-h-[400px] overflow-y-scroll lg:w-[360px] mt-1 ${
        theme === "dark" ? "bg-[#1D221C]" : "bg-white"
      }  shadow-md rounded-lg z-50 ${showNotifications ? "block" : "hidden"}`}
    >
      <div className="flex justify-between mt-4 p-3">
        <div
          className={`text-[18px] font-[600] ${
            theme === "dark" ? "text-white" : "text-[#141522]"
          }`}
        >
          Notifications
        </div>
        {notifications.length > 0 && (
          <div
            className="text-[14px] text-[#9C9CA4] font-[500]"
            onClick={handleClearNotifs}
          >
            Clear All
          </div>
        )}
      </div>
      {notifications.length == 0 && (
        <div className="flex text-[18px] items-center justify-center text-[#9C9CA4] font-[400] mb-6 mt-4">
          No notifications found.
        </div>
      )}
      {notifications.length > 0 &&
        notifications.map((notification, index) => (
          <div key={index}>
            <div className="p-2 flex justify-between mt-2">
              <div className="flex">
                <div className="flex">
                  <div className="rounded-full bg-[#F4F5FB] p-2 w-10 h-10 flex justify-center items-center">
                    {notificationIcons[notification.type]}
                  </div>
                  <div className="ml-3">
                    <div
                      className={`text-[14px] font-[400] ${
                        theme === "dark" ? "text-white" : "text-[#141522]"
                      }`}
                    >
                      {notification.message}
                    </div>
                    <div className="text-[12px] mt-1 text-[#9C9CA4] font-[400]">
                      {new Date(notification.date).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {index < notifications.length - 1 && (
              <hr className={`ml-2 mr-2 mt-2 `} />
            )}
          </div>
        ))}
    </div>
  );
}
