import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { ThemeContext } from "../../../state/ThemeContext";

const TempWidgetConsole = ({
  value,
  min,
  max,
  text,
  color,
  grow,
  chart,
  onEdit,
  onDelete,
  sensor,
}) => {
  const theme = useContext(ThemeContext).theme;

  // Define ITEM_HEIGHT here

  // Define the chart options
  const options = {
    chart: {
      type: "radialBar",
      height: 350,
      foreColor: theme === "dark" ? "#ffffff" : "#000000",
    },
    colors: [color],
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        hollow: {
          size: "65%",
        },
        track: {
          background: "#fff",
          color: color,
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#ccc",
            opacity: 1,
            blur: 2,
          },
        },

        dataLabels: {
          name: {
            show: true, // Show "Temperature" label
            offsetY: -10, // Offset label position
            color: color,
            fontWeight: "400",
            formatter: function () {
              return text;
            },
          },
          value: {
            fontSize: "35px",
            fontWeight: "bold",
            color: color,
            show: true,
            formatter: function (val) {
              val = parseInt(val);
              return sensor === "temperature"
                ? val.toFixed(1) + "°"
                : val.toFixed(1);
            },
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        backgroundColor: color,
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Temperature"],
    background: {
      image: "",
      imageWidth: 0,
      imageHeight: 0,
      imageOffsetX: 0,
      imageOffsetY: 0,
    },
  };

  return (
    <div
      className={`spline-area-chart rounded-[12px] shadow-md  bg-white dark:bg-dark2 p-[15px]`}
    >
      <div className="flex justify-between items-center px-[20px]">
        <span className="dark:text-white">{text}</span>
        <div
          style={{
            backgroundColor: "rgba(210, 210, 210, 0.25)",
            borderRadius: "4px",
          }}
        ></div>
      </div>
      {value && (
        <ReactApexChart
          options={options}
          series={[sensor === "temperature" ? value : 0]}
          type="radialBar"
        />
      )}

      <div className="min-max-values flex justify-center gap-[20%]">
        <span className="min-value " style={{ color: color }}>
          {min}&deg;
        </span>
        <span className="max-value " style={{ color: color }}>
          {max}&deg;
        </span>
      </div>
    </div>
  );
};

export default TempWidgetConsole;
