import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../state/ThemeContext";
import { useUser } from "../../state/UserContext";
import dayjs from "dayjs";
import { getLogsByUser } from "../../services/userLogsService";
import clear_icon from "../../assets/images/clear.svg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import CustomSnackbar from "../../components/CustomSnackbar";
import action_logs from "../../assets/images/logs.svg";
export default function ActivityLogs() {
  const { theme } = useContext(ThemeContext);
  const { userData } = useUser();
  const [dateToSearch, setdateToSearch] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [userLogs, setUserLogs] = useState([]);
  const [error, setError] = useState(null);
  const [openClearModal, setClearModal] = useState(false);
  const [logsSnackbar, setLogsSnackbar] = useState(false);
  const [toggledDate, setToggledDate] = useState(null);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const logsData = await getLogsByUser(userData.id);
        setUserLogs(logsData);
      } catch (error) {
        setError(error.message);
      }
    };
    fetchLogs();
  }, [userData.id]);

  const filterLogsByDate = () => {
    if (!dateToSearch) {
      return userLogs;
    }
    return userLogs.filter((log) =>
      dayjs(log.created_at).isSame(dateToSearch, "day")
    );
  };
  const handleDateChange = (date) => {
    setdateToSearch(date);
    setShowCalendar(false);
  };

  const toggleDate = (date) => {
    if (toggledDate === date) {
      setToggledDate(null);
    } else {
      setToggledDate(date);
    }
  };

  const renderLogsByDate = () => {
    const groupedLogs = {};
    filterLogsByDate().forEach((log) => {
      const date = dayjs(log.created_at).format("YYYY-MM-DD");
      if (!groupedLogs[date]) {
        groupedLogs[date] = [];
      }
      groupedLogs[date].push(log);
    });
    return Object.entries(groupedLogs)
      .sort(
        ([dateA], [dateB]) => dayjs(dateB).valueOf() - dayjs(dateA).valueOf()
      )
      .map(([date, logs], index) => (
        <div key={index}>
          <div
            className="flex items-center gap-3 mt-4 cursor-pointer"
            onClick={() => {
              toggleDate(date);
            }}
          >
            <div className="flex items-center">
              <div className="absolute w-8 h-8 bg-[#515977] ml-2 mb-3 mr-5 rounded-full -start-3 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time
                className={`mb-3 ml-7 text-[20px] font-[600] leading-none ml-2 ${
                  theme === "dark" ? "text-white" : "text-[#313131]"
                }`}
              >
                {dayjs(date).isSame(dayjs(), "day")
                  ? "Today"
                  : dayjs(date).isSame(dayjs().subtract(1, "day"), "day")
                  ? "Yesterday"
                  : dayjs(date).format("DD/MM/YYYY")}
              </time>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="9"
              viewBox="0 0 15 9"
              fill="none"
              className={`${
                toggledDate === date ? "rotate-icon" : ""
              } mt-[-10px]`}
            >
              <path
                d="M1 1.27591L7.44817 7.72408L13.8963 1.27591"
                stroke="#606873"
                strokeWidth="1.93445"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {toggledDate === date && (
            <ol className="relative border-s ml-2 pl-2 border-gray-200 dark:border-gray-700">
              {logs.map((log, logIndex) => (
                <li key={logIndex}>
                  <p
                    className={`text-[17px] font-[500] ${
                      theme === "dark" ? "text-white" : "text-[#313131]"
                    }`}
                  >
                    {" "}
                    - {log.action}
                  </p>
                  <p className="text-[#A5A5A5] text-[14px] font-[400]">
                    <strong>On</strong>{" "}
                    {dayjs(log.created_at).format("MMMM DD YYYY")}{" "}
                    <strong>At</strong> {dayjs(log.created_at).format("HH:mm")}
                  </p>
                </li>
              ))}
            </ol>
          )}
        </div>
      ));
  };

  // clear logs modal
  const handleOpenClearModal = () => {
    setClearModal(true);
  };

  return (
    <div className="mx-[32px] mt-[36px] mb-10 relative">
      <div className="flex flex-col sm:flex-row sm:justify-between items-center">
        <div className="w-full">
          <h2
            className={`text-[25px] font-[600] ${
              theme === "dark" ? "text-white" : "text-black"
            }`}
          >
            Activity Logs
          </h2>
        </div>
        <div className="flex flex-col sm:flex-row sm:items-end sm:justify-end sm:space-x-2 w-full">
          <button
            className={`flex mt-8 sm:mt-0 items-center justify-center gap-1 sm:max-w-[250px] rounded-[10px] sm:text-[16px] text-[15px] font-[500] pl-2 pr-3 py-[2px] sm:h-[45px] h-[45px] transition-all duration-75 active:scale-90`}
            style={{
              backgroundColor:
                localStorage.getItem("theme-color") || userData.themeColor,
            }}
            onClick={() => setShowCalendar(!showCalendar)}
          >
            <span className="text-white font-[500] text-[18px] ml-2">
              Custom Date
            </span>
          </button>

          {userLogs.length > 0 && (
            <button
              className={`flex mt-8 sm:mt-0 items-center sm:max-w-[180px] justify-center gap-1  rounded-[10px] sm:text-[16px] text-[15px] font-[500] px-[30px] py-[2px] sm:h-[45px] h-[45px] transition-all duration-75 active:scale-90 ${
                theme === "dark" ? " bg-[#F2F2F2]" : "bg-[#F4F5FB]"
              }`}
              type="button"
              onClick={handleOpenClearModal}
            >
              <span className={`text-[18px] font-[500] text-black`}>Clear</span>
              <img src={clear_icon} alt="clear icon" className="ml-2" />
            </button>
          )}
        </div>
      </div>

      {showCalendar && (
        <div
          className={`absolute top-15 sm:top-18 sm:right-1 w-[290px] sm:w-[350px]  shadow-md rounded-lg z-50 ${
            showCalendar ? "block" : "hidden"
          } ${theme === "dark" ? "bg-[#CACACA]" : "bg-white"}`}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateCalendar", "DateCalendar"]}>
              <div style={{ padding: "20px" }}>
                <DateCalendar
                  date={dateToSearch}
                  onChange={handleDateChange}
                  renderDay={(day, selectedDate, DayProps) => {
                    return <div {...DayProps}>{dayjs(day).format("D")}</div>;
                  }}
                />
              </div>
            </DemoContainer>
          </LocalizationProvider>
        </div>
      )}

      <div className="mt-8">
        {renderLogsByDate().length > 0 ? (
          renderLogsByDate()
        ) : (
          <div className="flex justify-center items-center mt-32">
            <div>
              <img src={action_logs} className="w-60 h-60" />
              <p
                className={`mt-4 font-[700] text-[20px] ml-4 ${
                  theme === "dark" ? "text-white " : "text-black"
                }`}
              >
                No Activity logs found !
              </p>
            </div>
          </div>
        )}
      </div>

      <CustomSnackbar
        openSnackbar={logsSnackbar}
        closeSnackbar={() => {
          setLogsSnackbar(false);
        }}
        message="All your logs are cleared successfully!"
      />
    </div>
  );
}
