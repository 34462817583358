import React from "react";
import "./sensors.css";
import sensorsList from "../../../assets/images/sensors-list.png";
import tindieLogo from "../../../assets/images/tindie-logo.svg";
import arrowIcon from "../../../assets/images/arrow-icon.svg";

export default function Sensors() {
  const navigateToTindie = () => {
    window.open(
      "https://www.tindie.com/products/sensy32/sensy32-all-in-one-sensor-iot-board-with-lcd/",
      "_blank"
    );
  };

  return (
    <div className="background-sensors-section flex flex-col justify-center items-center gap-[50px]">
      <h3
        className={`text-[#061C3D] text-[32px] sm:text-[42px] font-[600] font-Inter`}
      >
        {" "}
        Embedded Sensors{" "}
      </h3>
      <img
        className="sensors-list-image"
        src={sensorsList}
        alt="sensors-list"
        loading="lazy"
      />
      <button
        className="sensors-section-button"
        onClick={() => navigateToTindie()}
      >
        <img alt="buy button" src={tindieLogo} />
        Buy from Tindie
        <img className="rotation" alt="arrow icon" src={arrowIcon} />
      </button>
    </div>
  );
}
